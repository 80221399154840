<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-if="!loading" class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editdiscounts") }}
        </h3>
      </div>
      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="button"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="secondaryProductCode"
            class="required form-label col-sm-10"
            >{{ $t("discountname") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.discount_name"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
              required
            />
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="secondaryProductName"
            class="required form-label col-sm-10"
            >{{ $t("discountprice") }}</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.discount_price"
              type="text"
              class="form-control"
              name="secondaryProductName"
              id="secondaryProductName"
              placeholder="0-99"
              required
              maxlength="2"
              v-mask-number
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="card-body">
          <div class="card-toolbar row justify-content-center">
            <div
              class="col-sm-5"
              style="
                border: solid lightgray 0.5px;
                padding: 20px;
                border-radius: 10px;
              "
            >
              <b
                ><u>{{ $t("ลูกค้าทั้งหมด") }}</u></b
              >
              <div>
                <input
                  v-model="filterleftArray"
                  type="text"
                  class="form-control"
                  placeholder="ตัวกรอง"
                />
              </div>
              <div>
                <el-checkbox
                  type="checkbox"
                  v-model="allLeftCheckbox"
                  @change="checkAllitemsleftArray()"
                />
                <span class="form-check-label"> เลือกทั้งหมด </span>
              </div>
              <div
                v-for="customer in newLeftArray"
                :key="customer"
                class="col-sm-12 me-1"
              >
                <label
                  class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-2 mt-2"
                >
                  <el-checkbox type="checkbox" v-model="customer.isSelected" />
                  <span class="form-check-label">
                    {{ customer.code }} {{ customer.name }}
                  </span>
                </label>
              </div>
            </div>
            <div class="col-sm-1 mt-6" style="text-align: center">
              <button
                type="button"
                class="btn btn-light btn-primary"
                style="font-size: 14px; font-weight: bold"
                @click="appendRightArray()"
              >
                <i class="fas fa-arrow-right"></i>
              </button>
              <button
                type="button"
                class="btn btn-light btn-danger"
                style="font-size: 14px; font-weight: bold; margin-top: 10px"
                @click="appendLeftArray()"
              >
                <i class="fas fa-arrow-left"></i>
              </button>
            </div>
            <div
              class="col-sm-5 ms-1"
              style="
                border: solid lightgray 0.5px;
                padding: 20px;
                border-radius: 10px;
              "
            >
              <b
                ><u>{{ $t("ลูกค้าที่เลือก") }}</u></b
              >
              <div>
                <input
                  v-model="filterrightArray"
                  type="text"
                  class="form-control"
                  placeholder="ตัวกรอง"
                />
              </div>
              <div>
                <el-checkbox
                  type="checkbox"
                  v-model="allRightCheckbox"
                  @change="checkAllitemsrightArray()"
                />
                <span class="form-check-label"> เลือกทั้งหมด </span>
              </div>
              <div
                v-for="item in newRightArray"
                :key="item"
                class="col-sm-12 me-1"
              >
                <label
                  class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-2 mt-2"
                >
                  <el-checkbox type="checkbox" v-model="item.isSelected" />
                  <span class="form-check-label">
                    {{ item.code }} {{ item.name }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import customerAPI from "@/api/customer/";
import userAPI from "@/api/user/";

export default {
  setup() {
    document.title = "CHOMTHANA | แก้ไขประเภทสินค้า";
  },
  components: {
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    isSubmit: false,

    form: {
      // code: "",
      // name: "",
      // productGroupId: "",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    mainProduct: [],
    routeData: [],
    itemsleftArray: [],
    itemsrightArray: [],
    allLeftCheckbox: false,
    allRightCheckbox: false,
    filterleftArray: "",
    filterrightArray: "",
    newLeftArray: [],
    newRightArray: [],
  }),

  async created() {
    // await this.getMainProduct();
    await this.getAllCustomer();
    await this.getOne();
    await this.getAllDiscountUser();
    // await this.getAllCustomer();
  },
  watch: {
    filterleftArray(val) {
      let result = this.itemsleftArray.filter(
        (element) =>
          // element.van_name == val
          element.name.includes(val) || element.code.includes(val)
      );
      console.log("result", result);
      return (this.newLeftArray = result);
    },
    filterrightArray(val) {
      let result = this.itemsrightArray.filter(
        (element) =>
          // element.van_name == val
          element.name.includes(val) || element.code.includes(val)
      );
      console.log("result", result);
      return (this.newRightArray = result);
    },
  },

  methods: {
    checkAllitemsleftArray() {
      if (this.allLeftCheckbox) {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = false;
        });
      }
    },
    checkAllitemsrightArray() {
      if (this.allRightCheckbox) {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = false;
        });
      }
      this.newRightArray = this.itemsrightArray;
    },
    appendRightArray() {
      this.itemsleftArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          console.log("aaaaaaaaaa", a);
          this.itemsrightArray.push(a);
        }
      });
      this.itemsleftArray = this.itemsleftArray.filter(
        (item) => item.isSelected !== true
      );
      this.allLeftCheckbox = false;
      this.newLeftArray = this.itemsleftArray;
    },
    appendLeftArray() {
      this.itemsrightArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          this.itemsleftArray.push(a);
        }
        this.itemsrightArray = this.itemsrightArray.filter(
          (item) => item.isSelected !== true
        );
      });
      this.allRightCheckbox = false;
      this.newLeftArray = this.itemsleftArray;
      this.newRightArray = this.itemsrightArray;
    },
    //////////////////////////////
    async getAllDiscountUser() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAllByDiscountId(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.itemsrightArray = responseData.data;
        this.newRightArray = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllCustomer() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await customerAPI.customer.getAll(
          localStorage.getItem("branchId"),
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // let getVan = responseData.data;
        this.itemsleftArray = responseData.data;
        this.newLeftArray = responseData.data;

        // console.log("getVan left", getVan);
        // console.log("itemsrightArray left", this.itemsrightArray);
        // this.itemsrightArray.forEach((element) => {
        //   this.itemsleftArray = getVan.filter((val) => val.id !== element.id);
        // });

        console.log("this.itemsleftArray", this.itemsleftArray);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getMainProduct() {
      let responseData = [];
      try {
        responseData = await customerAPI.discount.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.mainProduct = responseData.data;
        // this.form.productGroupId = responseData.data[0].id;
      }
    },
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await customerAPI.discount.getOne(
          this.$route.query.id
        );
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
          // productGroupId: getOneResponse.data.mainProductGroupId,
        };
        this.loading = false;
      }
    },
    async submit() {
      Swal.fire({
        icon: "question",
        title: `${this.$t("want2submit")} ${this.$t("ornot")} ?`,
        confirmButtonText: `${this.$t("ok")} `,
        cancelButtonText: `${this.$t("cancel")} `,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let updateResponse = [];

          await this.validateForm();
          await this.checkFormIsEmpty();
          this.isSubmit = true;

          this.form = {
            ...this.form,
            mainProductGroupId: this.form.productGroupId,
            customers: this.itemsrightArray,
            // status: this.form.status === "2" ? "0" : this.form.status,
            companyId: parseInt(localStorage.getItem("companyId")),
          };
          if (this.isValid) {
            this.loadingUpdate = true;
            updateResponse = await customerAPI.discount.update(
              this.$route.query.id,
              this.form
            );
            if (updateResponse.response_status === "SUCCESS") {
              Swal.fire({
                icon: "success",
                title: `แก้ไขสำเร็จ`,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                this.$router.push({
                  path: "/CUSTOMER/discount/index",
                  query: {},
                });
              });
              this.loadingUpdate = false;
            } else {
              Swal.fire({
                icon: "error",
                title: `${updateResponse.message}`,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loadingUpdate = false;
            }
          }
        }
      });
    },

    checkFormIsEmpty() {
      !this.form.discount_price
        ? (this.isValid = false)
        : !this.form.discount_name
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/CUSTOMER/discount/index",
        query: {},
      });
    },
  },
};
</script>
